import ActiveTrainingCreators from "../../redux/reducers/activeTrainingReducer";
import SettingsCreators from "../../redux/reducers/settingsReducer";
import CoachControlCreators from "../../redux/reducers/coachControlsReducer";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "modal-react-native-web";
import { Image, View, TextInput } from "react-native";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IMG } from "../../assets/index";
import { MAIN_THEME } from "../../themes";
import { IconButton, DefaultText } from "../index";
import { themedStyles } from "./styles";
import { CONNECTION_STATUS, ICON } from "../../constants";
import { StateInterface } from "../../redux/reducers";
import { COURSE_MOVEMENT_TYPE, TRAINING_STATUS_TYPES, TRAINING_TYPES } from "../../redux/types";
import { StopTrainingModal } from "../Modals/StopTrainingModal";
import { RFValue } from "../../utils/resizeHelper";
import { Event } from "../../services/googleAnalyticsTracking";
import { LIMIT_VALUES } from "../../constants";
import { StackNavigationProp } from "@react-navigation/stack";
import { StackParams } from "../../navigation/index.web";
import { useNavigation } from "@react-navigation/core";

type HeaderProps = {
  onFullScreenPress: () => void;
  onBleButtonPress: () => void;
  isFullScreen: boolean;
  isCoachScreen?: boolean;
};

type NavigationProps = StackNavigationProp<StackParams, "LiveSession">;

function HeaderComponent({
  blePowerMeterConnectionStatus,
  bleSmartTrainerConnectionStatus,
  trainingStatus,
  criticalPower,
  isCoachScreen,
  isFullScreen,
  trainingType,
  smartTrainer,
  deviceGrade,
  currentStep,
  user,
  steps,
  skipInterval,
  pauseTraining,
  resumeTraining,
  restartSession,
  onBleButtonPress,
  onFullScreenPress,
  updateCriticalPower,
  changeTrainingStatus,
}: CombinedProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cp20Value, setCp20Value] = useState(criticalPower);
  const [isSkipButtonDisable, setIsSkipButtonDisable] = useState(false);

  const [maxGrade, setMaxGrade] = useState(smartTrainer?.maxGrade || 0);
  const { replace } = useNavigation<NavigationProps>();

  useEffect(() => {
    if (smartTrainer) {
      setMaxGrade(smartTrainer.maxGrade);
    }
  }, [smartTrainer]);

  useEffect(() => {
    setCp20Value(criticalPower);
  }, [criticalPower]);

  useEffect(() => {
    if (steps && steps.length > 0) {
      const maxGradeStep = steps.reduce((acc, curr) =>
        (acc.slope || 0) > (curr.slope || 0) ? acc : curr,
      );

      if (maxGradeStep?.slope) {
        setMaxGrade(maxGradeStep?.slope);
      } else if (smartTrainer?.maxGrade) {
        setMaxGrade(smartTrainer?.maxGrade);
      }
    }
  }, [steps, smartTrainer]);

  // useEffect(() => {
  //   if (smartTrainer && !!currentStep?.slope) {
  //     smartTrainer.setGrade(currentStep.slope);
  //   } else if (smartTrainer) {
  //     smartTrainer.setGrade(0);
  //   }
  // }, [currentStep, smartTrainer]);

  useEffect(() => {
    if (smartTrainer === null) {
      return;
    }
    
    if(currentStep?.courseMovementStatus === COURSE_MOVEMENT_TYPE.MOVING) {
      // Do not change the grade if currently on a moving course
      return;
    }

    if (!!currentStep?.slope) {
      smartTrainer.setGrade(currentStep.slope);
      return;
    }

    smartTrainer.setGrade(0);
  }, [currentStep, smartTrainer]);

  const onModalYesPress = useCallback(() => {
    setIsModalVisible(false);
    changeTrainingStatus(TRAINING_STATUS_TYPES.FINISHED);
    if (trainingType === TRAINING_TYPES.LIVE_SESSION) {
      replace("Welcome");
    }
  }, [setIsModalVisible, changeTrainingStatus, trainingType, replace]);

  const onModalNoPress = useCallback(() => {
    setIsModalVisible(false);
    resumeTraining();
  }, [setIsModalVisible, resumeTraining]);

  const isBleConnect =
    blePowerMeterConnectionStatus === CONNECTION_STATUS.CONNECTED ||
    bleSmartTrainerConnectionStatus === CONNECTION_STATUS.CONNECTED;
  const isControlButtonsDisable = trainingStatus === TRAINING_STATUS_TYPES.NOT_INITIALIZED;
  const isControlButtonsVisible = trainingType !== TRAINING_TYPES.LIVE_SESSION || isCoachScreen;

  const onLogoutPress = useCallback(() => {
    if (isCoachScreen) {
      if (isFullScreen) {
        onFullScreenPress();
      }
      setIsModalVisible(true);
    } else {
      if (trainingType === TRAINING_TYPES.LIVE_SESSION) {
        if (isFullScreen) {
          onFullScreenPress();
        }
        setIsModalVisible(true);
      } else changeTrainingStatus(TRAINING_STATUS_TYPES.FINISHED);
    }
  }, [
    changeTrainingStatus,
    onFullScreenPress,
    setIsModalVisible,
    isCoachScreen,
    isFullScreen,
    trainingType,
  ]);

  const onPlayPress = useCallback(() => {
    if (trainingStatus === TRAINING_STATUS_TYPES.PENDING_START) {
      skipInterval();
    } else {
      resumeTraining();
    }
    Event("click", { target: "start_workout" });
  }, [resumeTraining, trainingStatus, skipInterval]);

  const onFastForward = useCallback(() => {
    if (!isSkipButtonDisable && trainingType === TRAINING_TYPES.LIVE_SESSION) {
      skipInterval();
      setIsSkipButtonDisable(true);
      setTimeout(() => {
        setIsSkipButtonDisable(false);
      }, 1000);
    } else if (!isSkipButtonDisable) {
      skipInterval();
    }
    Event("click", { target: "skip_interval" });
  }, [skipInterval, isSkipButtonDisable, setIsSkipButtonDisable, trainingType]);

  const onPausePress = useCallback(() => {
    pauseTraining();
    Event("click", { target: "pause_workout" });
  }, [pauseTraining]);

  const onStopPress = useCallback(() => {
    pauseTraining();
    if (isFullScreen) {
      onFullScreenPress();
    }
    setIsModalVisible(true);
    Event("click", { target: "stop_workout" });
  }, [pauseTraining, setIsModalVisible, isFullScreen, onFullScreenPress]);

  const onBlePress = useCallback(() => {
    onBleButtonPress();
  }, [onBleButtonPress]);

  const onReloadPress = useCallback(() => {
    restartSession();
  }, [restartSession]);

  const onIncreaseGrade = useCallback(() => {
    if (smartTrainer) {
      smartTrainer.increaseGrade();
    }
  }, [smartTrainer]);

  const onDecreaseGrade = useCallback(() => {
    if (smartTrainer) {
      smartTrainer.decreaseGrade();
    }
  }, [smartTrainer]);

  const onIncreaseMaxGrade = useCallback(() => {
    setMaxGrade(maxGrade + 0.5);
  }, [maxGrade, setMaxGrade]);

  const onDecreaseMaxGrade = useCallback(() => {
    setMaxGrade(maxGrade - 0.5);
  }, [maxGrade, setMaxGrade]);

  const gradeControlButtons = [
    {
      icon: ICON.LEVEL_UP,
      onPress: onIncreaseGrade,
      size: RFValue(24),
    },
    {
      text: `${deviceGrade.toFixed(1)}%`,
      size: RFValue(24),
      onPress: () => {},
    },
    {
      icon: ICON.LEVEL_DOWN,
      onPress: onDecreaseGrade,
      size: RFValue(24),
    },
  ];

  const arrControlButtons =
    trainingStatus === TRAINING_STATUS_TYPES.PAUSED ||
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START_PAUSED ||
    trainingStatus === TRAINING_STATUS_TYPES.PENDING_START
      ? [
          {
            icon: ICON.PLAY,
            onPress: onPlayPress,
            size: RFValue(24),
            tooltip: "Play",
          },
          {
            icon: ICON.PAUSE,
            onPress: onPausePress,
            size: RFValue(24),
            tooltip: "Pause",
          },
          {
            icon: ICON.SKIP,
            onPress: onFastForward,
            size: RFValue(24),
            tooltip: "Skip",
          },
          {
            icon: ICON.RELOAD,
            onPress: onReloadPress,
            size: RFValue(24),
            tooltip: "Reload",
          },
          {
            icon: ICON.STOP,
            onPress: onStopPress,
            size: RFValue(24),
            tooltip: "Stop",
          },
        ]
      : [
          {
            icon: ICON.PAUSE,
            onPress: onPausePress,
            size: RFValue(24),
            tooltip: "Pause",
          },
          {
            icon: ICON.SKIP,
            onPress: onFastForward,
            size: RFValue(24),
            tooltip: "Skip",
          },
          {
            icon: ICON.RELOAD,
            onPress: onReloadPress,
            size: RFValue(24),
            tooltip: "Reload",
          },
          {
            icon: ICON.STOP,
            onPress: onStopPress,
            size: RFValue(24),
            tooltip: "Stop",
          },
        ];

  const controlButtons =
    trainingType === TRAINING_TYPES.LIVE_SESSION
      ? arrControlButtons // .filter((item) => item.icon !== ICON.SKIP)
      : arrControlButtons.filter((item) => item.icon !== ICON.RELOAD);

  const onCriticalValueChange = (text: string) => {
    let value = parseInt(text);
    if (!value) {
      value = 0;
    }
    if (value < +LIMIT_VALUES.MIN_CP20M) {
      value = +LIMIT_VALUES.MIN_CP20M;
    }
    if (value > +LIMIT_VALUES.MAX_CP20M) {
      value = +LIMIT_VALUES.MAX_CP20M;
    }
    text = value.toFixed();
    setCp20Value(+text);
  };

  const submitCp20 = () => {
    updateCriticalPower(cp20Value);
  };

  const submitMaxGrade = () => {
    if (smartTrainer != null) {
      smartTrainer.maxGrade = maxGrade;
      if (maxGrade < deviceGrade) {
        smartTrainer.setGrade(maxGrade);
      }
    }
  };

  const maxGradeControlButtons = [
    {
      icon: ICON.LEVEL_UP,
      onPress: onIncreaseMaxGrade,
      size: RFValue(16),
    },
    {
      text: `${maxGrade.toFixed(1)}%`,
      onPress: submitMaxGrade,
      size: RFValue(16),
    },
    {
      icon: ICON.LEVEL_DOWN,
      onPress: onDecreaseMaxGrade,
      size: RFValue(16),
    },
  ];

  return (
    <View style={themedStyles.container}>
      <Modal animationType="fade" transparent={true} visible={isModalVisible}>
        <StopTrainingModal onModalNoPress={onModalNoPress} onModalYesPress={onModalYesPress} />
      </Modal>
      <View style={themedStyles.userInfoContainer}>
        <Image source={{ uri: user.profile?.picture }} style={themedStyles.userPortfolioImg} />
        <DefaultText style={themedStyles.userName}>
          {user.cpxProfile?.name || user.profile?.full_name}
        </DefaultText>
        <IconButton
          oneButton={{
            icon: ICON.LOGOUT,
            onPress: onLogoutPress,
            color: MAIN_THEME.HEADER.ALTERNATIVE_ICON_COLOR,
            size: RFValue(29),
          }}
          style={themedStyles.logoutButton}
        />
        {smartTrainer && (
          <View style={themedStyles.setMaxGradeContainer}>
            <IconButton
              oneButton={{
                text: "Max grade:",
                onPress: submitMaxGrade,
                size: RFValue(16),
              }}
              style={themedStyles.maxGradeButtonContainer}
            />
            <IconButton
              arrButton={maxGradeControlButtons}
              style={themedStyles.maxGradeButtonContainer}
            />
          </View>
        )}
      </View>

      <View style={themedStyles.logoContainer}>
        <Image source={IMG.logo} style={themedStyles.logoImg} />
      </View>

      <View style={themedStyles.controlMenuContainer}>
        <View style={themedStyles.processControlContainer}>
          <DefaultText style={themedStyles.cp}>CP20m:</DefaultText>
          <TextInput
            value={`${cp20Value}`}
            style={themedStyles.numberParam}
            keyboardType="number-pad"
            onChangeText={onCriticalValueChange}
            onBlur={submitCp20}
          />
          {smartTrainer && <IconButton arrButton={gradeControlButtons} />}
          <IconButton
            oneButton={{
              icon: ICON.BLUETOOTH,
              onPress: onBlePress,
              isActive: isBleConnect,
              color: MAIN_THEME.HEADER.ACTIVE_BUTTON_ICON_COLOR,
              size: RFValue(24),
            }}
            tooltip="Bluetooth"
          />
          {!isCoachScreen && (
            <IconButton
              oneButton={{
                icon: ICON.DISPLAY,
                onPress: onFullScreenPress,
                isActive: isFullScreen,
                color: MAIN_THEME.HEADER.ACTIVE_BUTTON_ICON_COLOR,
                size: RFValue(24),
              }}
              tooltip="FullScreen"
            />
          )}
          {isControlButtonsVisible ? (
            <IconButton arrButton={controlButtons} isDisable={isControlButtonsDisable} />
          ) : (
            <View />
          )}
        </View>
      </View>
    </View>
  );
}

type CombinedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  HeaderProps;

const mapStateToProps = (state: StateInterface) => ({
  blePowerMeterConnectionStatus: state.devices.blePowerMeterConnectionStatus,
  bleSmartTrainerConnectionStatus: state.devices.bleSmartTrainerConnectionStatus,
  trainingStatus: state.activeTraining.status,
  user: state.user,
  trainingType: state.trainingDetails.trainingType,
  criticalPower: state.settings.criticalPower,
  smartTrainer: state.devices.smartTrainerDevice,
  deviceGrade: state.devices.grade,
  currentStep: state.activeTraining.currentSteps.currentStep,
  steps: state.activeTraining.plan?.steps
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // Training
  resumeTraining: () => dispatch(ActiveTrainingCreators.resumeTraining()),
  stopTraining: () => dispatch(ActiveTrainingCreators.stopTraining()),
  pauseTraining: () => dispatch(ActiveTrainingCreators.pauseTraining()),
  skipInterval: () => dispatch(ActiveTrainingCreators.nextStep()),
  changeTrainingStatus: (status: TRAINING_STATUS_TYPES) =>
    dispatch(ActiveTrainingCreators.changeTrainingStatus(status)),
  updateCriticalPower: (criticalPower: number) =>
    dispatch(SettingsCreators.updateCriticalPower(criticalPower)),
  restartSession: () => dispatch(CoachControlCreators.restartSession()),
});

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
