import store from "..";
import DevicesCreators from "../reducers/devicesReducer";
import NotificationsCreators from "../reducers/notificationsReducer";
import { put, select } from "redux-saga/effects";
import {
  BLE_REMARK_TYPES,
  CONNECTION_STATUS_TYPES,
  DeviceDataPoint,
  DeviceType,
  SearchForBleDeviceAction,
  CreateBluetoothSensorAction,
  NotificationLocation,
  TypesOfNotification,
  ReconnectDeviceAction,
} from "../types";
import { StateInterface } from "../reducers";
import { Event } from "../../services/googleAnalyticsTracking";
import {
  getDeviceServiceUuids,
  SERVICE_UUID_CYCLING_POWER,
  SERVICE_UUID_USER_DATA,
  SERVICE_UUID_WATTBIKE_ATOMX_PROPRIETARY,
  SERVICE_UUID_WATTBIKE_ATOM_PROPRIETARY,
} from "../../sensors/common/BleMeterCommon";
import { SensorBase } from "../../sensors/common/SensorBase";
import { BleHeartRateMonitor } from "../../sensors/BleHeartRateMonitor.web";
import { BleSpeedCadenceSensor } from "../../sensors/BleSpeedCadenceSensor.web";
import { BleCyclingPowerMeter } from "../../sensors/BleCyclingPowerMeter.web";
import { SmartTrainer } from "../../sensors/SmartTrainer.web";
import { DebugApi } from "../../api/api";

function* disconnectDevice(existingDevice: SensorBase, deviceType: DeviceType) {
  existingDevice.disconnect();

  yield put(DevicesCreators.updateSpecificBluetoothSensor(null, deviceType));

  yield put(
    DevicesCreators.bleStatusChanged(
      deviceType,
      CONNECTION_STATUS_TYPES.NOT_CONNECTED,
      BLE_REMARK_TYPES.NOT_CONNECTED,
    ),
  );
}

export function* reconnectDevice({ bluetoothSensor, device, deviceType }: ReconnectDeviceAction) {
  console.log("Trying to reconnect to device...");
  try
  {
    yield connectBluetoothDevice(device, deviceType);

    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.Device,
        notificationType: TypesOfNotification.Info,
        description:
          "Successfully reconnected to BLE sensor.",
      }),
    );
  }
  catch
  {
    yield disconnectDevice(bluetoothSensor, deviceType);

    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.Device,
        notificationType: TypesOfNotification.Error,
        description: "Unable to reconnect to BLE sensor.",
      }),
    );
  }
}

export function* searchForBleDevices({ deviceType }: SearchForBleDeviceAction) {
  try {
    // If already connected, disconnect device
    let existingDevice: SensorBase | null = null;
    switch (deviceType) {
      case DeviceType.SmartTrainer:
        existingDevice = yield select((state: StateInterface) => state.devices.smartTrainerDevice);
        break;
      case DeviceType.PowerMeterDevice:
        existingDevice = yield select((state: StateInterface) => state.devices.powerMeterDevice);
        break;
      case DeviceType.SpeedCadenceSensor:
        existingDevice = yield select(
          (state: StateInterface) => state.devices.speedCadenceSensorDevice,
        );
        break;
      case DeviceType.HeartRateMeterDevice:
        existingDevice = yield select(
          (state: StateInterface) => state.devices.heartRateMeterDevice,
        );
        break;
    }

    if (existingDevice) {
      yield disconnectDevice(existingDevice, deviceType);

      // We disconnected an existing device, so we are done.
      return;
    }

    let serviceUuids = getDeviceServiceUuids(deviceType);

    // Create array of BluetoothLEScanFilters, one for each returned Service UUID
    let filters: BluetoothLEScanFilter[] = [];
    for (let uuid of serviceUuids) {
      if (deviceType === DeviceType.SmartTrainer && uuid === SERVICE_UUID_CYCLING_POWER) {
        // Special case for KICKR Legacy control.  Characteristic is part of power service,
        // so we need to filter based on the name prefix.
        filters.push({ services: [uuid], namePrefix: "KICKR CORE" });
        filters.push({ services: [uuid], namePrefix: "KICKR SNAP" });
        filters.push({ services: [uuid], namePrefix: "Wahoo KICKR" });
        filters.push({ services: [uuid], namePrefix: "KICKR BIKE" });
      } else {
        filters.push({ services: [uuid] });
      }
    }

    const options = {
      filters: filters,
      optionalServices: [
        SERVICE_UUID_USER_DATA,
        SERVICE_UUID_WATTBIKE_ATOM_PROPRIETARY,
        SERVICE_UUID_WATTBIKE_ATOMX_PROPRIETARY,
      ],
    };

    yield put(
      DevicesCreators.bleStatusChanged(
        deviceType,
        CONNECTION_STATUS_TYPES.NOT_CONNECTED,
        BLE_REMARK_TYPES.NOT_CONNECTED,
      ),
    );
    Event("click", { target: "connect ble" });

    const available: boolean = yield navigator.bluetooth.getAvailability();

    if (typeof navigator.bluetooth === "undefined" || !available) {
      Event("ble_connection", {
        status: "connection failed",
        error: "Bluetooth is not supported",
      });
      DebugApi.log({
        function: "searchForBleDevices",
        event: "BLE Connection Error",
        error: "Bluetooth is not supported",
      });
      yield put(
        DevicesCreators.bleStatusChanged(
          deviceType,
          CONNECTION_STATUS_TYPES.FAILED,
          BLE_REMARK_TYPES.NO_BLE_SUPPORT,
        ),
      );

      return false;
    }

    const device: BluetoothDevice | undefined = yield navigator.bluetooth
      .requestDevice(options)
      .catch((error: Error) => {
        // Ignore case of user cancelling from device pair screen, which is a NotFoundError
        if (error.name !== "NotFoundError") {
          throw error;
        }
      });

    if (device) {
      yield put(DevicesCreators.createBluetoothSensor(device, deviceType));
    }
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.Device,
        notificationType: TypesOfNotification.Error,
        description: "Ble connection error: " + error,
      }),
    );
    Event("ble_connection", {
      status: "connection failed",
      error: error,
    });
    DebugApi.log({
      function: "searchForBleDevices",
      event: "BLE Connection Error",
      error: "" + error,
    });
    yield put(
      DevicesCreators.bleStatusChanged(deviceType, CONNECTION_STATUS_TYPES.FAILED, "" + error),
    );
  }
}

function* connectBluetoothDevice(device: BluetoothDevice, deviceType: DeviceType) {
  const server: BluetoothRemoteGATTServer = yield device?.gatt?.connect();
  Event("ble_connection", { status: "found server" });
  yield put(
    DevicesCreators.bleStatusChanged(
      deviceType,
      CONNECTION_STATUS_TYPES.CONNECTING_SERVER,
      BLE_REMARK_TYPES.FOUND_SERVER,
    ),
  );

  const services: BluetoothRemoteGATTService[] = yield server?.getPrimaryServices();

  Event("ble_connection", { status: "found service" });
  yield put(
    DevicesCreators.bleStatusChanged(
      deviceType,
      CONNECTION_STATUS_TYPES.CONNECTING_SERVICE,
      BLE_REMARK_TYPES.FOUND_SERVICE,
    ),
  );

  let bluetoothSensor: SensorBase;

  const weight: number = yield select(
    (state: StateInterface) => state.user.cpxProfile?.weight || 80,
  );

  const height: number = yield select(
    (state: StateInterface) => state.user.cpxProfile?.height || 1.75,
  );

  switch (deviceType) {
    case DeviceType.PowerMeterDevice:
      bluetoothSensor = new BleCyclingPowerMeter(device, server, services, weight);
      break;
    case DeviceType.HeartRateMeterDevice:
      bluetoothSensor = new BleHeartRateMonitor(device, server, services);
      break;
    case DeviceType.SpeedCadenceSensor:
      bluetoothSensor = new BleSpeedCadenceSensor(device, server, services);
      break;
    case DeviceType.SmartTrainer:
      bluetoothSensor = new SmartTrainer(device, server, services, weight, height);
      break;
    default: {
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.Device,
          notificationType: TypesOfNotification.Info,
          description: "Unknown device",
        }),
      );
      return;
    }
  }

  // Add error handling for Bluetooth sensor
  if (bluetoothSensor.bleDevice) {
    bluetoothSensor.bleDevice.OnError = (errorMessage) => {
      DebugApi.log({
        function: "bleDevice.OnError",
        message: errorMessage,
      });
      store.dispatch(
        NotificationsCreators.addNotification({
          location: NotificationLocation.Device,
          notificationType: TypesOfNotification.Error,
          description: "Bluetooth Error: " + errorMessage,
        }),
      );
    };

    bluetoothSensor.bleDevice.OnInfo = (message) => {
      store.dispatch(
        NotificationsCreators.addNotification({
          location: NotificationLocation.Device,
          notificationType: TypesOfNotification.Info,
          description: message,
        }),
      );
    };

    bluetoothSensor.bleDevice.OnDebug = (message) => {
      console.log(message);
      DebugApi.log({
        function: "bleDevice.OnDebug",
        message: message,
      });
    };

    bluetoothSensor.bleDevice.OnUnexpectedDisconnect = async () => {
      // Notify user of disconnection
      store.dispatch(
        NotificationsCreators.addNotification({
          location: NotificationLocation.Device,
          notificationType: TypesOfNotification.Info,
          description:
            "Unexpected Bluetooth Sensor Disconnect.  Trying to reconnect to sensor...",
        }),
      );

      store.dispatch(DevicesCreators.reconnectDevice(bluetoothSensor, device, deviceType));
    };
  }

  yield bluetoothSensor.SetupCharacteristics();

  if (bluetoothSensor.bleDevice != null) {
    bluetoothSensor.bleDevice.setDeviceUpdateListener((value: DeviceDataPoint) => {
      store.dispatch(DevicesCreators.deviceDataChanged(value, deviceType));
    });
  }

  yield put(DevicesCreators.updateSpecificBluetoothSensor(bluetoothSensor, deviceType));

  Event("ble_connection", {
    status: "connected",
    trainer_type: bluetoothSensor.name,
  });

  yield put(
    DevicesCreators.bleStatusChanged(
      deviceType,
      CONNECTION_STATUS_TYPES.CONNECTED,
      BLE_REMARK_TYPES.CONNECTED,
      device.name,
    ),
  );
}

export function* createBluetoothSensor({ device, deviceType }: CreateBluetoothSensorAction) {
  try {
    if (deviceType === DeviceType.UnknownDevice) {
      yield put(
        NotificationsCreators.addNotification({
          location: NotificationLocation.Device,
          notificationType: TypesOfNotification.Error,
          description: "Must know device type before connecting to device.",
        }),
      );
      return;
    }

    Event("ble_connection", { status: "found device" });
    yield put(
      DevicesCreators.bleStatusChanged(
        deviceType,
        CONNECTION_STATUS_TYPES.CONNECTING_DEVICE,
        BLE_REMARK_TYPES.FOUND_DEVICE,
      ),
    );

    yield connectBluetoothDevice(device, deviceType);
  } catch (error) {
    yield put(
      NotificationsCreators.addNotification({
        location: NotificationLocation.Device,
        notificationType: TypesOfNotification.Error,
        description: "Ble connection error: " + deviceType + error,
      }),
    );
    Event("ble_connection", { status: "connection failed", error: "" + error });
    DebugApi.log({
      function: "createBluetoothSensor",
      event: "BLE Connection Error",
      context: "Device: " + JSON.stringify(device) + ". Type: " + deviceType,
      error: "" + error,
      stack: (error as any).stack,
    });

    yield put(
      DevicesCreators.bleStatusChanged(deviceType, CONNECTION_STATUS_TYPES.FAILED, "" + error),
    );
  }
}

export function* deviceDisconnected() {
  yield put(
    NotificationsCreators.addNotification({
      location: NotificationLocation.Device,
      notificationType: TypesOfNotification.Info,
      description: "Device is disconnected.",
    }),
  );
  yield put(
    DevicesCreators.bleStatusChanged(
      //TODO handle device types??
      DeviceType.PowerMeterDevice,
      CONNECTION_STATUS_TYPES.NOT_CONNECTED,
      BLE_REMARK_TYPES.DEVICE_DISCONNECTED,
    ),
  );
}
